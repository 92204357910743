import React, { Component } from 'react';
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
         <div className="row">

            <div className="three columns">

               <img className="profile-pic"  src="images/profilepic.jpg" alt="" />

            </div>

            <div className="nine columns main-col">

               <h2>About Me!!</h2>
               <p>
               { resumeData.aboutme }
               </p>
               <p>
               { resumeData.aboutmecont }
               </p>

               <div className="row">

                  <div className="columns contact-details">

                  <h2>Contact Details</h2>
                  <p className="address">
                  
                  < i class="fa fa-user" aria-hidden="true"></i>
       						<a>     {resumeData.name}</a>
                     <br></br>
                   <i class="fa fa-envelope-o"></i>
                  <a href={`mailto:${resumeData.email}`} target="_blank"> {resumeData.email}</a>
                    <br></br>
                    <i class="fa fa-mobile" aria-hidden="false">  
                     </i> 
                     <a>   {resumeData.phone} </a>
                    <br></br>
<i class="fa fa-map-marker" aria-hidden="true"></i>

                     <a> {resumeData.address}
                    </a>
       					   </p>
                  </div>

                  <div class="columns download">
                  <p>
                     <a href="AhmedMohammedResume.pdf" class="button" target="_blank"><i class="fa fa-download"></i>Download Resume</a>
                  </p>
               </div>

               </div>
            </div>
         </div>
      </section>
    );
  }
}
