import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
     <section id="portfolio">

      <div class="row">

         <div class="twelve columns collapsed">

            <h1>Some of My Workerrwe</h1>

            <div id="portfolio-wrapper" class="bgrid-quarters s-bgrid-thirds cf">

               <div class="columns portfolio-item">
                  <div class="item-wrap">

                     <a href="#modal-01" title="">
                        <img alt="" src="modalimg/musicplayer.jpg"/>
                        <div class="overlay">
                           <div class="portfolio-item-meta">
                                  <h5>Soup Music Player</h5>
                              <p>Mobile App</p>
                              <p>Swift</p>
                               </div>
                        </div>
                        <div class="link-icon"><i class="icon-plus"></i></div>
                     </a>

                  </div> 
                </div>

               <div class="columns portfolio-item">
                  <div class="item-wrap">

                     <a href="#modal-02" title="">
                        <img alt="" src="modalimg/classroom.jpg"/>
                        <div class="overlay">
                           <div class="portfolio-item-meta">
                                  <h5>Computer Science Master's Degree Search</h5>
                              <p>Web Development</p>
                              <p>Django</p>
                               </div>
                        </div>
                        <div class="link-icon"><i class="icon-plus"></i></div>
                     </a>

                  </div>
                </div> 

               <div class="columns portfolio-item">
                  <div class="item-wrap">

                     <a href="#modal-03" title="">
                        <img alt="" src="modalimg/shoppingcart.jpg"/>
                        <div class="overlay">
                           <div class="portfolio-item-meta">
                                  <h5>E-Commerce</h5>
                              <p>Web app</p>
                              <p>Ruby on Rails</p>
                               </div>
                        </div>
                        <div class="link-icon"><i class="icon-plus"></i></div>
                     </a>

                  </div>
                </div> 

               <div class="columns portfolio-item">
                  <div class="item-wrap">

                     <a href="#modal-04" title="">
                        <img alt="" src="modalimg/sortingalgs.jpg"/>
                        <div class="overlay">
                           <div class="portfolio-item-meta">
                                  <h5>Sorting Algorithms</h5>
                              <p>Mobile App</p>
                              <p>Swift</p>

                               </div>
                        </div>
                        <div class="link-icon"><i class="icon-plus"></i></div>
                     </a>

                  </div>
                </div> 
 
            </div> 

         </div> 


         <div id="modal-01" class="popup-modal mfp-hide">

         <Carousel showThumbs={false} dynamicHeight={true}>


             <div>
              <img class="scale-with-grid" src="modalimg/souplibrary.png"  alt=""/>
              </div>
              <div>
              <img class="scale-with-grid" src="modalimg/soupplayer.png" alt=""/>
              </div>
              <div>
              <img class="scale-with-grid" src="modalimg/soupinfo.png" alt=""/>
              </div>
              <div>
              <img class="scale-with-grid" src="modalimg/souplyriclist.png" alt=""/>
              </div>
              <div>
              <img src="modalimg/souplyrics.png" alt=""/>
              </div>
              <div>
              <img class="scale-with-grid" src="modalimg/soupsearch.png" alt=""/>
            </div>

         </Carousel>

              <div class="description-box">
                  <h4>Soup Music Player</h4>
                  <p>Music player that plays the downloaded music on phone.</p>
               <span class="categories"><i class="fa fa-tag"></i>Swift, iOS, Mobile App</span>
              </div>

            <div class="link-box">
               <a href="https://github.com/amoham37/Soup-Music-Player" target="_blank">Details</a>
                 <a class="popup-modal-dismiss">Close</a>
            </div>

          </div>

         <div id="modal-02" class="popup-modal mfp-hide">
        <Carousel showThumbs={false} dynamicHeight={true}>

              <img  src="modalimg/csdmain.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/csdprof.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/csdreport.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/csdreport2.png" alt=""/>
        
        </Carousel>
              <div class="description-box">
                  <h4>Computer Science Master's Degree Search</h4>
                  <p>Web app that find the shortest path to find a degree path</p>
               <span class="categories"><i class="fa fa-tag"></i>Django, Web Development, MySQL</span>
              </div>

            <div class="link-box">
               <a href="https://github.com/amoham37/csc394_capstonefinal" target="_blank">Details</a>
                 <a class="popup-modal-dismiss">Close</a>
            </div>

          </div>

         <div id="modal-03" class="popup-modal mfp-hide">
            <Carousel showThumbs={false}>
              <img class="scale-with-grid" src="modalimg/ecommain.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/ecomsell.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/ecomsignup.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/ecomcart1.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/ecomcart2.png" alt=""/>
                    </Carousel>  
              <div class="description-box">
                  <h4>E-Commerce</h4>
                  <p>E-commerce web app for user to list items to sell or buy items listed by other users</p>
               <span class="categories"><i class="fa fa-tag"></i>Rails, SQLite</span>
              </div>

            <div class="link-box">
               <a href="https://github.com/amoham37/eCommerce-Store" target="_blank">Details</a>
                 <a class="popup-modal-dismiss">Close</a>
            </div>

          </div>

         <div id="modal-04" class="popup-modal mfp-hide">
            <Carousel showThumbs={false}>
              <img class="scale-with-grid" src="modalimg/sortalgmain.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/sortalgbub.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/sortalgins.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/sortalgmerge.png" alt=""/>
              <img class="scale-with-grid" src="modalimg/sortalginfo.png" alt=""/>
                     </Carousel> 
              <div class="description-box">
                  <h4>Sorting Algorithms</h4>
                  <p>Mobile app that sorts list of numbers based on algorithm desired (Merge Sort, Bubble Sort, Insertion Sort ) and see each step along the way </p>
               <span class="categories"><i class="fa fa-tag"></i>Data Structures, iOS, Swift</span>
              </div>

            <div class="link-box">
               <a href="http://www.github.com/amoham37/Sorting-Algorithms" target="_blank">Details</a>
                 <a class="popup-modal-dismiss">Close</a>
            </div>

          </div>



      </div> 

   </section>
        );
  }
}
