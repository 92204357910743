let resumeData = {
    // "imagebaseurl":"https://techonoeticsclub.github.io/",
    "name": "Ahmad Mohammedasds",
    "role": "Software Engineer ",
    "linkedinId": "Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": " based in Niles, IL. While having Java and Swift background, I am passionate about learning new technologies in which allows me to be versatile in every stage of product development.  ",
    "socialLinks": [{
            "name": "linkedin",
            "url": "https://www.linkedin.com/in/ahmad-mohammed-87915a163/",

            "className": "fa fa-linkedin"
        },
        {
            "name": "github",
            "url": "http://github.com/amoham37",
            "className": "fa fa-github"
        }
        // ,
        // {
        //   "name":"skype",
        //   "url":"http://skype.com",
        //   "className":"fa fa-skype"
        // }
    ],
    "aboutme": "I am someone who takes pride in their work.  I strive to create and develop software that is robust, secure and efficient. This enables me to be open to learn and try new technologies. Currently, I work as a full stack softwar engineer for State Farm where I am expanding my expertise by offering solutions mostly in Java, Javascript along side Python. ",
    "aboutmecont": "Along side coding, I am also very passionate about photography and soccer. ",
    "phone": "847-682-0149",
    "email": "amoham811@gmail.com",
    "address": "Niles, IL",
    // "website":"https://www.codeclassifiers.com",
    "education": [{
            "UniversityName": "DePaul University",
            "specialization": "Bachelor of Science in Computer Science",
            "MonthOfPassing": "June, ",
            "YearOfPassing": "2019",
            // "Achievements":"Some Achievements"
        }
        // {
        // "UniversityName":"Some University",
        // "specialization":"Some specialization",
        // "MonthOfPassing":"Jan",
        // "YearOfPassing":"2018",
        // "Achievements":"Some Achievements"
        // }
    ],
    "work": [{
            "CompanyName": "State Farm Insurance Company",
            "specialization": "Software Engineer",
            "MonthOfStarting": "February,",
            "YearOfStarting": "2020",
            "MonthOfLeaving": "Present",
            // "YearOfLeaving": ""
            // "Achievements":"Some Achievements"
        },

        {
            "CompanyName": "Chicago Public Schools",
            "specialization": "Computer Science Teacher Assistant",
            "MonthOfStarting": "January,",
            "YearOfStarting": "2018",
            "MonthOfLeaving": "March,",
            "YearOfLeaving": "2018"
                // "Achievements":"Some Achievements"
        },
        //   {
        //     "CompanyName":"Some Company",
        //     "specialization":"Some specialization",
        //     "MonthOfLeaving":"Jan",
        //     "YearOfLeaving":"2018",
        //     "Achievements":"Some Achievements"
        //   }
    ],
    // "skillsDescription":"Your skills here",
    "skills": [{
            "skillname": "Java"
        },
        {
            "skillname": "Python"
        },
        {
            "skillname": "Swift"
        },
        {
            "skillname": "C-Sharp"
        },
        {
            "skillname": "HTML5"
        },
        {
            "skillname": "Javascript"
        },
        {
            "skillname": "SQL"
        }
    ],
    "portfolio": [{
            "name": "Soup Music Player",
            "description": "Mobile App",
            "language": "Swift",
            "imgurl": "images/projimg.png",
            "projimg1": "images/profilepic.jpg",
            "projimg2": "images/projimg.png",
            "projimg3": "images/projimg.png",
        },
        {
            "name": "Computer Science Master's Degree Search",
            "description": "Web App",
            "language": "Django",
            "imgurl": "images/projimg.png",
            "projimg1": "images/projimg.png",
            "projimg2": "images/projimg.png",
            "projimg3": "images/projimg.png",
        },
        {
            "name": "E-Commerce",
            "description": "Web App",
            "language": "Ruby on Rails",
            "imgurl": "images/projimg.png",
            "projimg1": "images/projimg.png",
            "projimg2": "images/profilepic.jpg",
            "projimg3": "images/projimg.png",
        },
        {
            "name": "Sort Algorithms",
            "description": "Mobile App",
            "language": "Swift",
            "imgurl": "images/projimg.png",
            "projimg1": "images/projimg.png",
            "projimg2": "images/projimg.png",
            "projimg3": "images/projimg.png",
        }
    ],
    "testimonials": [{
            "description": "Fast, good, cheap. Pick only two.",
            "name": "Unknown"
        },
        {
            "description": "Worry is a misuse of the imagination",
            "name": "Dan Zadra"
        },
        {
            "description": "Do what you can, with what you have, where you are.",
            "name": "Theodore Roosevelt"
        }
    ]
}

export default resumeData