import React, { Component } from 'react';
import axios from 'axios';

export default class ContactUs extends Component {







  render() {
    let resumeData = this.props.resumeData;


    return (

    
      // <section id="contact">
      //     <div className="row section-head">
      //       <div className="ten columns">
      //         <p className="lead">
      //         Feel free to contact me for any work or suggestions below
      //         </p>
      //       </div>
      //     </div>
      //     <div className="row">
      //       <aside className="eigth columns footer-widgets">
      //         <div className="widget">
      //           <h4>Linked in :
      //             {resumeData.linkedinId}
      //           </h4>
      //         </div>
      //       </aside>
      //     </div>
      //   </section>
    

      <section id="contact">

         <div class="row section-head">

            <div class="two columns header-col">

               <h1><span>Get In Touch.</span></h1>

            </div>

            <div class="ten columns">

                  <p class="lead">Feel free to contact me.
                  </p>

            </div>

         </div>
         

         <div class="row">

            <div class="eight columns">

               <form action="https://formspree.io/xnqawpdq" method="post" id="contactForm" name="contactForm">
          <fieldset>

               <label for="contactName">Name <span class="required">*</span></label>
               <input type="text" id="contactName" name="contactName" required="required"
               />

                  <div>
               <label for="contactEmail">Email <span class="required">*</span></label>
               <input type="email" size="35" id="contactEmail" name="_replyto" required="required"
               />
                  </div>

                  <div>
               <label for="contactSubject">Subject</label>
               <input type="text" size="35" id="contactSubject" name="contactSubject"
               /> 
                  </div>

                  <div>
                     <label for="contactMessage">Message <span class="required">*</span></label>
                     <textarea cols="50" rows="15" id="contactMessage" name="contactMessage" required="required" >

                       </textarea>
                  </div>

                  <div>

                      
                     <button type ="submit" id="submit" name="submit">Submit</button>
                      
                     <span id="image-loader">
                        <img alt="" src="images/loader.gif"/>
                     </span>
                  </div>

          </fieldset>
           </form>

               <div id="message-warning"> Error boy</div>
           <div id="message-success">
                  <i class="fa fa-check"></i>Your message was sent, thank you!<br>
                  </br>
           </div>

            </div>

      </div>

   </section>
        );
  }
}
